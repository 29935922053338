<i18n locale="th" lang="yaml" >
page.title : "{name} :: การเปลี่ยนแปลงอะไหล่"
page.header : "ประวัติการเปลี่ยนแปลงอะไหล่ของ Drone ตัวนี้"
page.description : "รายการการเปลี่ยนแปลงอะไหล่ทั้งหมดของ Drone ตัวนี้ จากงานซ่อมที่ปิดสถานะเรียบร้อยแล้ว"

drone_parts.filter.field.keyword.placeholder : "ชื่ออะไหล่ หรือ SKU"
drone_parts.filter.field.change_type.placeholder : "ประเภท"
drone_parts.filter.field.is_claim_only : "แสดงที่มีการเคลมเท่านั้น"

</i18n>
<template>
	<div class="page">
		<div class="page-action-right">
			<a-button icon="left" @click="goBack">
				{{$t('common.back')}}
			</a-button>
		</div>
		<my-page-header
			:title="$t('page.header',{ name: drone.serialNo })"
			:description="$t('page.description',{ name: drone.serialNo })"/>
		<a-card :bordered="false">
			<div class="part-filter-pane">
				<div class="filter-input">
					<a-select v-model="filter.changeType"
						allow-clear
						:options="changeTypeOptions"
						style="width:120px"
						:placeholder="$t('drone_parts.filter.field.change_type.placeholder')">
					</a-select>
				</div>
				<div class="filter-input">
					<a-input v-model="filter.keyword"
						allow-clear
						style="width:200px"
						:placeholder="$t('drone_parts.filter.field.keyword.placeholder')"/>
				</div>
				<div class="filter-input">
					<a-checkbox v-model="filter.isClaimOnly">
						{{$t('drone_parts.filter.field.is_claim_only')}}
					</a-checkbox>
				</div>
			</div>
			<ServicePartTable
				bordered
				:row-key="record => record.id"
				:data-source="partsList"
				:pagination="pagination"
				:loading="loading"
				@change="handleTableChange" />
		</a-card>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import {Checkbox,Select} from "ant-design-vue"
import {emptyDrone} from "@utils/objectUtil"
import ServicePartTable from "@components/service/ServicePartTable.vue"
import {getEnumSelectOptions} from "@utils/formUtil"
import debounce from "lodash/debounce"
import ApiError from "@utils/errors/ApiError"
import axios from "axios"

export default {
	components : {
		"a-checkbox" :  Checkbox, "a-select" : Select,
		ServicePartTable,
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name : this.drone.serialNo})
		}
	} ,
	props : {
		droneData : {
			type : null,
			default : emptyDrone()
		} ,
	} ,
	data() {
		return {
			loading : false,
			partsList : [] ,

			filter : {
				isClaimOnly : false,
				keyword : undefined,
				changeType : undefined,
			} ,

			pagination : {
				pageSize : 25,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
			} ,
			sort : {
				field : 'serviceReturnDate',
				order : 'descend',
			} ,
		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		} ,
		changeTypeOptions() {
			return getEnumSelectOptions(this,'service.part.change_type',['repair','normal','recall'])
		}
	} ,
	watch : {
		filter : {
			handler() {
				this.debounceSearchChange();
			} ,
			deep: true,
		},
		droneData : {
			handler() {
				this.fetchData()
			} ,
			deep : true
		} ,
	} ,
	created() {
		this.debounceSearchChange = debounce(this.handleSearchChange,300);
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		handleSearchChange() {
			// Reset Pagination
			this.fetchData(1);
		} ,
		fetchData(toPage=null) {

			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				pageSize : this.pagination.pageSize ,
				currentPage : toPage,
				sortField : this.sort.field ,
				sortOrder : this.sort.order ,
			}
			const filter = {
				...this.filter,
			};
			const droneId = this.drone.id

			this.loading = true
			axios.get("/api/services/search-parts",{params : {droneId, search, filter}}).then((response) => {
				const page = response.data.data.pagination
				this.partsList = response.data.data.serviceParts
				this.pagination.current = toPage
				this.pagination.total = page.total
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		} ,
		handleTableChange(pagination,filters,sorter) {
			this.sort.field = sorter.columnKey;
			this.sort.order = sorter.order;
			this.fetchData(pagination.current);
		} ,
		goBack() {
			this.$open({name : 'drone/view' ,params : {serial_no : this.drone.serialNo}})
		}
	}
}
</script>
<style lang="less" scoped>
.part-filter-pane {
	display : flex;
	flex-wrap: wrap;
	margin-bottom : 16px;
	align-items: center;
	justify-content : flex-end;

	.filter-input {
		margin-right : 8px;
		margin-bottom : 8px;
	}
}
</style>
