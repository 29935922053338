<i18n locale="th" lang="yaml" >
part_table.field.return_date : "วันที่"
part_table.field.service_no : "ใบงาน"
part_table.field.drone : "Drone"
part_table.field.change_type : "ประเภท"
part_table.field.num_change : "เปลี่ยน"
part_table.field.num_claim : "เคลม"
</i18n>

<template>
	<a-table
		v-bind="$attrs"
		class="mytable mytable-scroll service-part-table"
		size="small"
		:pagination="pagination"
		v-on="$listeners">
		<a-table-column
			:title="$t('common.table.column.no')"
			align="center"
			class="column-row-no">
			<template v-slot="text,record,index">
				{{index + 1 + ((pagination.current - 1) * pagination.pageSize)}}
			</template>
		</a-table-column>
		<a-table-column
			key="serviceReturnDate" :sorter="true"
			data-index="service.return_date"
			:title="$t('part_table.field.return_date')"
			:width="125"
			class="column-font-small"
			align="center">
			<template v-slot="text">
				{{text | date}}
			</template>
		</a-table-column>
		<a-table-column v-if="!hideColumnService"
			key="serviceNo" :sorter="true"
			:title="$t('part_table.field.service_no')"
			class="column-font-small"
			:width="135">
			<template v-slot="{ service }">
				<my-router-link v-if="isShowView(service)" name="service/view" :param-value="service.id">
					{{service.service_no}}
				</my-router-link>
				<span v-else>
					{{service.service_no}}
				</span>
			</template>
		</a-table-column>
		<a-table-column
			key="changeType"
			:title="$t('part_table.field.change_type')"
			:width="120" align="center"
			data-index="servicePart.changeType">
			<template v-slot="text">
				{{$tenum('service.part.change_type',text)}}
			</template>
		</a-table-column>
		<a-table-column
			key="detail"
			class="part-detail-col"
			:title="$t('common.table.column.detail')">
			<template v-slot="record">
				<ServicePartDetailTableField
					:service-part-data="record"/>
			</template>
		</a-table-column>
		<a-table-column
			key="numChange" :title="$t('part_table.field.num_change')"
			data-index="servicePart.numberOfPartChange"
			:width=60 align="center">
			<template v-slot="text">
				{{text | integer('-')}}
			</template>
		</a-table-column>
		<a-table-column
			key="numClaim" :title="$t('part_table.field.num_claim')"
			data-index="servicePart.claimPartNumApproved"
			:width=60 align="center">
			<template v-slot="text">
				{{text | integer('-')}}
			</template>
		</a-table-column>
		<a-table-column
			data-index="servicePart.remark"
			:width="50"
			align="center">
			<template slot-scope="remark">
				<MyTooltip v-if="$notEmpty(remark)" display-mode="white"
					:destroy-tooltip-on-hide="true">
					<div slot="title" v-trim class="multiline">
						{{remark}}
					</div>
					<a-icon type="message" class="cursor-pointer"/>
				</MyTooltip>
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import {Table} from "ant-design-vue"
import { isStatusAllowView} from '@/src/utils/serviceUtil'
import MyTooltip from "@components/common/MyTooltip.vue"
import ServicePartDetailTableField from "@components/service/ServicePartDetailTableField.vue"
export default {
	components : {
		"a-table" : Table, "a-table-column" : Table.Column ,
		ServicePartDetailTableField , MyTooltip ,
	} ,
	props : {
		pagination : {
			type : null,
			default : () => []
		} ,
		hideColumns : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		hideColumnService() {
			return this.$notEmpty(this.hideColumns) && this.hideColumns.includes('service')
		} ,
	} ,
	methods : {
		isShowView(service) {
			if (this.$authorize('read','service',{companyId : service.company_id}))
				return true
			else
				return isStatusAllowView('details',service.status)
		}
	}
}
</script>

<style lang="less" scoped>
.service-part-table::v-deep table {
	min-width : 1000px;
	td.part-detail-col {
		min-width : 200px;
	}
	td.column-font-small {
		font-size : 0.95em;
	}
}
.drone-serial-no {
	display : block;
	font-size : 0.9em;
	margin-top : 2px;
}
</style>
