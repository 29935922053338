
<i18n src="@i18n/drone/part.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
warranty_duration.display : 'รับประกัน {count} @:common.day'
warranty_duration.display.zero : 'ไม่ระบุระยะเวลารับประกัน'
additional_warranty.flight.display : 'ชั่วโมงบินไม่เกิน {count} @:common.hour'
additional_warranty.charge.display : 'ชาร์ตไม่เกิน {count} @:common.charge.unit'
</i18n>

<template>
<a-card
	v-bind="$attrs"
	:bordered="bordered"
	:class="cardCssClasses">
	<div class="mycard-content">
		<div class="mycard-header">
			<my-router-link name="part/view" :param-value="displayPart.id" new-window auth-action="read" auth-module="part">
				<ImageLoader
					:src="displayPart.imageFileKey"
					class="mycard-image"
					:size="size"
					border-type="round">
					<a-icon type="deployment-unit" />
				</ImageLoader>
			</my-router-link>
		</div>
		<ul class="mycard-details">
			<li>
				<span class="title text-bold">{{displayPart.sku}}</span>
			</li>
			<li>
				{{displayPart.name}} <br />
			</li>
			<li>
				{{$t('part.field.part_type')}} : {{$tenum('part_type',displayPart.partType)}}
			</li>
			<li>
				{{displayWarrantyDetail}}
			</li>
			<li class="mycard-tags">
				<MyTooltip
					v-show="displayPart.has_serial_no"
					:title="$t('part.field.has_serial_no.tooltip')">
					<a-tag  color="#ffb7b2">Serial</a-tag>
				</MyTooltip>
				<MyTooltip
					v-show="displayPart.strict_use"
					:title="$t('part.field.strict_use.tooltip')">
					<a-tag color="#c7ceea">Match Model</a-tag>
				</MyTooltip>
				<MyTooltip
					v-show="displayPart.status === 'inactive'"
					:title="$t('part.field.status.inactive.tooltip')">
					<a-tag color="#bfbfbf">Inactive</a-tag>
				</MyTooltip>
			</li>
		</ul>

	</div>

</a-card>
</template>

<script>
import ImageLoader from "@components/common/ImageLoader.vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import {Tag} from "ant-design-vue"
export default {
	components : {
		ImageLoader ,
		"a-tag" : Tag, MyTooltip
	} ,
	props : {
		part : {
			type: null,
			default : () => []
		} ,
		bordered : {
			type: Boolean ,
			default : false,
		} ,
		size : {
			type: String,
			default: "",
		} ,
		linkTarget : {
			type : String,
			default : "_self"
		}
	} ,
	computed : {
		cardCssClasses() {
			return [
				"part-card","mycard","mycard-columns","mycard-with-details",
				{
					"mycard-sm" : this.size == 'small' ,
					"mycard-inactive" : this.notActiveStatus
				}
			]
		} ,
		displayPart() {
			return this.part && this.part.id ? this.part : {id:0}
		} ,
		notActiveStatus() {
			return this.part && this.part.id && this.part.status != 'active'
		} ,
		displayWarrantyDetail() {
			let rtn = this.$tc_my('warranty_duration.display',this.displayPart.warrantyDuration)
			if (this.displayPart.warrantyType && this.displayPart.warrantyValue > 0) {
				switch(this.displayPart.warrantyType) {
					case "flight" :
						rtn += ", " +this.$tc('additional_warranty.flight.display',this.displayPart.warrantyValue)
						break;
					case "charge" :
						rtn += ", " +this.$tc('additional_warranty.charge.display',this.displayPart.warrantyValue)
						break;
				}
			}
			return rtn;
		}
	}
}
</script>
