<i18n locale="th" lang="yaml" >
part_detail.field.part_display : "{name} (SKU : {sku})"
</i18n>

<template>
	<div class="part-detail-table">
		<div v-if="showSwapPartRow" class="part-detail">
			<div class="change-icon text-success">
				<a-icon type="swap" class="change-icon-action" />
				<DroneIcon />
			</div>
			<div v-if='$notEmpty(servicePart.newPartName)' class="part-name">
				{{servicePart.newPartName}}
			</div>
			<div v-else-if="$notEmpty(servicePartData.newPart)" class="part-name">
				<MyPopover overlay-class-name="mycard-popover" :destroy-tooltip-on-hide="true">
					<template slot="content">
						<PartLiteCard :part="servicePartData.newPart" size="small"  link-target="_blank" />
					</template>
					{{$t('part_detail.field.part_display',{ sku: servicePartData.newPart.sku, name: servicePartData.newPart.name })}}
				</MyPopover>
			</div>
		</div>
		<template v-else>
			<div v-if="showNewPartRow" class="part-detail">
				<div class="change-icon text-success">
					<a-icon type="arrow-right" class="change-icon-action"/>
					<DroneIcon />
				</div>
				<div v-if='$notEmpty(servicePart.newPartName)' class="part-name">
					{{servicePart.newPartName}}
				</div>
				<div v-else-if="$notEmpty(servicePartData.newPart)" class="part-name">
					<MyPopover overlay-class-name="mycard-popover" :destroy-tooltip-on-hide="true">
						<template slot="content">
							<PartLiteCard :part="servicePartData.newPart" size="small"  link-target="_blank" />
						</template>
						{{$t('part_detail.field.part_display',{ sku: servicePartData.newPart.sku, name: servicePartData.newPart.name })}}
					</MyPopover>
				</div>
			</div>
			<div v-if="showOldPartRow" class="part-detail">
				<div class="change-icon text-error">
					<a-icon type="arrow-left" class="change-icon-action" />
					<DroneIcon />
				</div>
				<div v-if='$notEmpty(servicePart.oldPartName)' class="part-name">
					{{servicePart.oldPartName}}
				</div>
				<div v-else-if="$notEmpty(servicePartData.oldPart)" class="part-name">
					<MyPopover overlay-class-name="mycard-popover" :destroy-tooltip-on-hide="true">
						<template slot="content">
							<PartLiteCard :part="servicePartData.oldPart" size="small"  link-target="_blank" />
						</template>
						{{$t('part_detail.field.part_display',{ sku: servicePartData.oldPart.sku, name: servicePartData.oldPart.name })}}
					</MyPopover>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import PartLiteCard from "@components/part/PartLiteCard.vue"
import MyPopover from "@components/common/MyPopover.vue"
import DroneIcon from "@components/icon/DroneIcon.vue"
export default {
	components : {
		PartLiteCard,MyPopover,DroneIcon
	} ,
	props : {
		servicePartData : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		servicePart() {
			return this.servicePartData.servicePart
		} ,
		showNewPartRow() {
			return this.$notEmpty(this.servicePart?.newPartName) || this.servicePartData?.newPart?.id
		} ,
		showOldPartRow() {
			return this.$notEmpty(this.servicePart?.oldPartName) || this.servicePartData?.oldPart?.id
		} ,
		showSwapPartRow() {
			if (this.$notEmpty(this.servicePart.newPartName)) {
				return this.servicePart?.newPartName == this.servicePart?.oldPartName
			} else if (this.servicePart?.newDroneServicePartId && this?.oldDroneServicePartId) {
				return this.servicePart?.newDroneServicePartId == this.servicePart?.oldDroneServicePartId
			} else {
				return false
			}
		}
	} ,
}
</script>

<style lang="less" scoped>
.part-detail {
	display : flex;
	flex-wrap: nowrap;
	align-items: center;
	margin-bottom : 4px;
	&:last-child {
		margin-bottom : 0;
	}
	.change-icon {
		font-size : 18px;
		margin-right : 4px;
	}
	.change-icon-action {
		font-size : 12px;
		line-height: 18px;
    vertical-align: middle;
		margin-right : -4px;
	}
	.part-name {
		vertical-align: middle;
		font-size : 0.9em;
	}
}

</style>
